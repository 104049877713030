<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
	<div>
		<v-navigation-drawer
				v-model="showDrawer"
				app
				right>
			<div v-if="filterDrawer">
				<v-card flat>
					<v-app-bar
							color="primary"
							dark
							elevation="0">
						<v-btn @click="closeDrawer()" class="ma-1" small icon>
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<p class="mt-10 mb-10">Фильтр</p>
					</v-app-bar>
					<v-divider></v-divider>
					<v-list>
						<v-list-item>
							<v-autocomplete
									v-model="selectFacultyList"
									:items="facultyList"
									item-text='shortname'
									item-value='id'
									label="Факультет"
									single-line
									clearable
									:disabled="loadingFacultyStatus"
									:loading="loadingFacultyStatus"
									@change="getGroupsOfFaculty">
							</v-autocomplete>
						</v-list-item>

						<v-list-item
								v-show="selectFacultyList != null">
							<v-autocomplete
									v-model="selectGroupList"
									label="Группа"
									:items="groupList"
									item-text="name"
									item-value="id"
									single-line
									clearable
									:disabled="loadingGroupStatus"
									:loading="loadingGroupStatus"
							>

							</v-autocomplete>
						</v-list-item>

						<v-list-item>
							<v-select
									v-model="selectCardStatus"
									:items="unionCardStatus"
									item-text="text"
									item-value="value"
									label="Статус"
									multiple
							>
								<template v-slot:selection="{ item, index }">
										<span v-if="index === 0">
											<span>{{ item.text }}</span>
										</span>
									<span
											v-if="index === 1"
											class="grey--text caption"
									> (+ ещё {{ selectCardStatus.length - 1 }})</span>
								</template>
							</v-select>
						</v-list-item>


						<v-list-item>
							<v-btn block color="primary"
										 @click="setFilter"
										 :loading="loadingItems">Поиск
							</v-btn>
						</v-list-item>
						<v-list-item>
							<v-btn block text color="primary" @click="resetFilter">Сбросить</v-btn>
						</v-list-item>
					</v-list>
				</v-card>
			</div>
			<div v-if="profileDrawer">
				<cont-profile-drawer :selectItem="profileItem"
														 @closeProfile="closeDrawer"></cont-profile-drawer>
			</div>
		</v-navigation-drawer>

		<v-card class="ma-xl-1 ma-sm-2 ma-xs-2">
			<v-card-title>Профсоюзный билет</v-card-title>
			<v-data-table
					:headers="headers"
					:items="items"
					:loading=loadingItems
					item-key="id"
					loading-text="Загрузка ..."
					fixed-header
					:mobile-breakpoint=321
					:server-items-length="totalItems"
					:items-per-page="15"
					:options.sync="options"
					:footer-props="{
						itemsPerPageOptions: [15, 30, 50, 100],
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-arrow-left',
						nextIcon: 'mdi-arrow-right'
					}"
					class="ma-2 ma-sm-0 ma-xl-0 ma-md-0 ma-lg-0"
			>
				<template v-slot:top>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
							<v-text-field
									v-model="searchString"
									@keydown.enter="searchUnionCard(searchString)"
									append-icon="mdi-magnify"
									class="ma-1 pa-2"
									label="Поиск по ФИО"
									dense
									clearable
							></v-text-field>
						</v-col>
						<v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="4" lg="6" xl="6">
							<v-spacer></v-spacer>
						</v-col>
						<v-col class="text-right" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
							<v-btn class="mr-3" outlined @click="openFilterDrawer">
								<v-icon>mdi-filter-variant</v-icon>
								Фильтр
							</v-btn>
						</v-col>
					</v-row>
				</template>

				<template v-slot:item.profile.fullname="{item}">
					<div class="d-flex expander">
						<div class="text-name" >
							{{item.profile.fullname}}
						</div>
					</div>
				</template>

				<template v-slot:item.profile.student.faculty.shortname="{item}">
					<div class="d-flex expander" >
						<div class="text-other" v-if="item.profile.student.faculty != null">
							{{item.profile.student.faculty.shortname}}
						</div>
					</div>
				</template>

				<template v-slot:item.profile.student.group.name="{item}">
					<div
							class="d-flex expander">
						<div class="text-other"
								 v-if="item.profile.student.group != null">
							{{item.profile.student.group.name}}
						</div>
					</div>
				</template>

				<template v-slot:item.status=" {item}">
					<v-btn
							text
							:color=getStatusColor(item.status)
							@click="openStatusDialog(item)"
					>{{getStatusText(item.status)}}
					</v-btn>
				</template>

        <template v-slot:item.profile.student.status="{item}">
          <div>
            {{ getStudentStatusAsText(item.profile.student.status) }}
          </div>
        </template>

        <template v-slot:item.cardType="{item}">
          <div>
            {{ getTypeCardText(item.cardType) }}
          </div>
        </template>


			</v-data-table>
		</v-card>

    <!--Диалог работы со статусами билета-->
		<v-dialog
				v-model="statusDialog"
				max-width="600px"
				:fullscreen="$vuetify.breakpoint.xsOnly">
			<v-card>
				<v-card-title>
          <v-btn icon @click="statusDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          Изменение статуса
        </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="subtitle-1">
          <v-container class="pl-5 pt-2">
            <v-row>
              <v-container class="pt-0">
                <v-row>
                  <div class="font-weight-bold pr-2">
                    <div  v-if="selectUnionCard.profile.sex === 'м'">Студент:</div>
                    <div v-else>Студентка:</div>
                  </div>
                  <div>{{ selectUnionCard.profile.fullname }}</div>
                </v-row>
                <v-row v-if="selectUnionCard.profile.elUnionCard != null">
                  <div class="font-weight-bold pr-2">ЭПБ:</div>
                  <div>{{ selectUnionCard.profile.elUnionCard }}</div>
                </v-row>
                <v-row v-if="selectUnionCard.profile.student.group != null">
                  <div class="font-weight-bold pr-2">Группа:</div>
                  <div>{{ selectUnionCard.profile.student.group.name }}</div>
                </v-row>
                <v-row v-if="selectUnionCard.cardType != null">
                  <div class="font-weight-bold pr-2">Тип билета:</div>
                  <div>{{ getTypeCardText(selectUnionCard.cardType) }}</div>
                </v-row>

              </v-container>
            </v-row>
            <v-row>
              <v-divider class="pb-0"></v-divider>
            </v-row>
            <v-row align="center">
                <v-col cols="4" class="text-center font-weight-bold">Изменение статуса:</v-col>
                <v-col cols="3" class="text-center" :style="{ color:getStatusColor(selectUnionCard.status)}">{{getStatusText(selectUnionCard.status)}}</v-col>
                <v-col cols="1" class="text-center font-weight-bold">	→</v-col>
                <!--<v-col cols="auto" :style="{ color:getStatusColor(getNextStatus(selectUnionCard.status))}">{{getStatusText(getNextStatus(selectUnionCard.status))}}</v-col>-->
                <v-col cols="3" class="text-center">
                  <v-select
                      v-model="nextStatus"
                      :items="unionCardStatus"
                      item-text="text"
                      item-value="value"
                      label="Новый статус"
                      single-line
                  >
                    <template v-slot:item="{ item }">
                      <div :style="{ color: item.color}">
                        {{ item.text }}
                      </div>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <div :style="{ color: item.color}">
                        {{ item.text }}
                      </div>
                    </template>
                  </v-select>
                </v-col>
            </v-row>
            <v-row v-if="nextStatus === 'ISSUED'">
              <v-col cols="12" sm="6">
                <v-text-field
                    label="Дата выдачи"
                    v-model="issueDate"
                    type="date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    :readonly="selectUnionCard.cardType === 'VIRTUAL'"
                    label="Ведомость выдачи"
                    v-model="issueStatement"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="primary" text @click="statusDialog=false">Отменить</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="setStatusUnionCard(selectUnionCard)">Подтвердить</v-btn>
					<v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="statementDialog"
				hide-overlay
				:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<v-card>
				<v-card-title>
					<v-btn
							@click="statementDialog=false"
							icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					Заявление на вступление
				</v-card-title>
				<v-card-text>
					<v-img
							class="grey lighten-2"
							:src="selectStatement">
						<template v-slot:placeholder>
							<v-row
									class="fill-height ma-0"
									align="center"
									justify="center"
							>
								<v-progress-circular indeterminate color="primary dark-5"></v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import moment from 'moment';
    import {getStudentStatusAsText, getTypeCard} from '@/utils/helpers'
    import {
      IN_WORK,
      PENDING,
      READY,
      ISSUED,
      CANCELED,
      CLOSED,
      CARD_TYPE_CARD,
      CARD_TYPE_PAPER, CARD_TYPE_VIRTUAL
    } from '@/share/const-strings';

    import {User} from "@/model/dto/User";

    export default {
        name: 'UnionCard',
        data() {
            return {
              myUserInfo: new User(),
                headers: [
                  {text: 'Статус', sortable: false, align: 'center', value: 'status'},
                  {text: 'ФИО', align: 'left', sortable: false, value: 'profile.fullname'},
                  {text: 'Тип билета', sortable: false, align: 'center', value: 'cardType'},
                  {text: 'Номер ЭПБ', sortable: false, align: 'center', value: 'profile.elUnionCard'},
                  {text: 'Курс', sortable: false, align: 'center', value: 'profile.student.group.course'},
                  {text: 'Факультет', sortable: false, align: 'center', value: 'profile.student.faculty.shortname'},
                  {text: 'Группа', sortable: false, align: 'center', value: 'profile.student.group.name'},
                  {text: 'Номер зачётки', sortable: false, align: 'center', value: 'profile.student.studentNumber'},
                  {text: 'Форма обучения', sortable: false, align: 'center', value: 'profile.student.educationForm'},
                  {text: 'Статус', sortable: false, align: 'center', value: 'profile.student.status'},


                ],
                options: {},
                unionCardStatus: [
                    PENDING,
                    IN_WORK,
                    READY,
                    ISSUED,
                    CANCELED,
                    CLOSED,
                ],
                loadingFacultyStatus: false,
                loadingGroupStatus: false,
                statusDialog: false,
                statementDialog: false,
                selectUnionCard: {
                    profile: {
                        fullname: '',
                        student: {}
                    },
                    status: '',
                },
                profileItem: {},
                selectStatement: '',
                currentStatus: '',
                nextStatus: '',
                searchString: '',
                issueDate: '2024.02.02.',  // Для хранения даты выдачи
                issueStatement: '',       // Для хранения примечания
                groupList: [],
                selectFacultyList: null,
                selectGroupList: null,
              selectCardStatus: [],
                filterDrawer: false,
                profileDrawer: false,
                showDrawer: false,
            };
        },
        methods: {

            getStudentStatusAsText(status) {
              return getStudentStatusAsText(status);
            },

            openStatusDialog(item) {
              const currentStatus = item.status;
              this.nextStatus = this.getNextStatus(currentStatus);
              this.issueDate = moment().locale('ru').format('YYYY-MM-DD');
              if (item.cardType === 'VIRTUAL') {
                this.issueStatement = 'Активация ЛК'

              } else {
                this.issueStatement = ''
              }
              if (currentStatus !== CLOSED.value
                  && currentStatus !== CANCELED.value
                  && currentStatus !== ISSUED.value) {
                  this.selectUnionCard = item;
                  this.statusDialog = true;
              }
            },

            getStatusColor(status) {
                switch (status) {
                    case PENDING.value:
                        return PENDING.color;
                    case IN_WORK.value:
                        return IN_WORK.color;
                    case READY.value:
                        return READY.color;
                    case ISSUED.value:
                        return ISSUED.color;
                    case CANCELED.value:
                        return CANCELED.color;
                    case CLOSED.value:
                        return CLOSED.color;
                    default:
                        return 'grey';
                }
            },
            getStatusText(status) {
                switch (status) {
                    case PENDING.value:
                        return PENDING.text;
                    case IN_WORK.value:
                        return IN_WORK.text;
                    case READY.value:
                        return READY.text;
                    case ISSUED.value:
                        return ISSUED.text;
                    case CANCELED.value:
                        return CANCELED.text;
                    case CLOSED.value:
                        return CLOSED.text;
                    default:
                        return 'Неизвестен';
                }
            },

            getTypeCardText(cardType) {
                  return getTypeCard(cardType);
              },

            getNextStatus(status) {
                switch (status) {
                    case PENDING.value:
                        return IN_WORK.value;
                    case IN_WORK.value:
                        return READY.value;
                    case READY.value:
                        return ISSUED.value;
                    case ISSUED.value:
                        return null;
                }
            },
            getGroupsOfFaculty() {

                this.groupList = [];
                this.selectGroupList = null;
                let facultyId = 0;
                if (this.selectFacultyList != null) {
                    this.loadingGroupStatus = true;
                    facultyId = this.selectFacultyList;
                    this.$store.dispatch('getGroupList', {faculty_id: facultyId}).then(() => {
                        this.groupList = this.$store.getters.getGroupList;
                        this.loadingGroupStatus = false;
                    });
                } else {
                    this.groupList = [];
                }
            },
            setStatusUnionCard(item) {
              if (this.nextStatus === 'ISSUED') {
                this.$store.dispatch('setStatusUnionCard',
                    {item,
                      nextStatus: this.nextStatus,
                      issueDate: this.issueDate,
                      issueStatement: this.issueStatement})
                    .then((response) => {
                      this.statusDialog = false;
                    });

              } else if (this.nextStatus != null) {
                  this.$store.dispatch('setStatusUnionCard', {item, nextStatus: this.nextStatus,})
                      .then((response) => {
                          this.statusDialog = false;
                      });
                }
            },
            searchUnionCard(searchString) {
                const facultyId = this.getAvailableFacultyId();
                if (searchString.length > 0) {
                    const searchStr = searchString.trim();
                    this.$store.dispatch('defaultLoadUnionCard', {
                        fullname: searchStr,
                        statuses: this.selectCardStatus,
                        facultyId: this.selectFacultyList,
                        groupId: this.selectGroupList,
                        page: 0,
                        limit: this.options.itemsPerPage,
                    }).then(() => {
                        this.options.page = 1;
                    });
                } else {
                    this.$store.dispatch('defaultLoadUnionCard', {
                        status: this.selectCardStatus,
                        facultyId: this.selectFacultyList,
                        groupId: this.selectGroupList,
                        page: 0,
                        limit: this.options.itemsPerPage,
                    }).then(() => {
                            this.options.page = 1;
                        },
                    );
                }
            },
            setFilter() {
                this.$store.dispatch('defaultLoadUnionCard', {
                    fullname: this.searchString,
                    statuses: this.selectCardStatus,
                    facultyId: this.selectFacultyList,
                    groupId: this.selectGroupList,
                    page: 0,
                    limit: this.options.itemsPerPage,
                }).then(() => {
                    this.options.page = 1;
                });
            },
            resetFilter() {
                this.selectCardStatus = [];
                this.selectFacultyList = null;
                this.selectGroupList = null;
            },
            getBirthdayDate(birthdayDate) {
                return moment(birthdayDate).locale('ru').format('L');
            },
            getJoinMemberDate(joinMemberDate) {
                if (joinMemberDate == null || joinMemberDate.memberFrom == null) {
                    return 'Отсутствует';
                }
                if (joinMemberDate.memberFrom != null) {
                    return joinMemberDate.memberFrom.substr(0, 4);
                }
            },
            getAvailableFacultyId() {
                const facultyList = this.$store.getters.getFacultyList;
                const facultyId = [];
                if (!this.$store.getters.getIsStaff || !this.$store.getters.getIsSuperUser) {
                    if (facultyList.length < 2) {
                        facultyId.push(this.$store.getters.getMyFaculty.id);
                        return facultyId;
                    } else if (facultyList.length === 3) {
                        if (this.selectFacultyList == null) {
                            for (const faculty of facultyList) {
                                facultyId.push(faculty.id);
                            }
                            return facultyId;
                        } else {
                            return this.selectFacultyList;
                        }
                    }
                } else {
                    return this.$store.getters.getSelectFaculties;
                }
            },
            openFilterDrawer() {
                this.profileDrawer = false;
                if (this.filterDrawer) {
                  this.showDrawer = false;
                  this.filterDrawer = false;
                } else {
                  this.filterDrawer = true;
                  this.showDrawer = true;
                };

            },

            closeDrawer() {
              this.showDrawer = false;
              this.profileDrawer = false;
              this.filterDrawer = false;
            },
        },
        computed: {
            items() {
                return this.$store.getters.getUnionCardItems;
            },
            totalItems() {
                return this.$store.getters.getUnionCardTotalItems;
            },
            loadingItems() {
                return this.$store.getters.getUnionCardLoaderItems;
            },
            facultyList() {
              return this.myUserInfo.getAvailableFaculties();
            },
        },

        beforeMount(){
          this.myUserInfo = this.$store.getters.getMyUserInfo;
          if (this.myUserInfo.getCountAvailableFaculties() === 1) {
            this.selectFacultyList = this.myUserInfo.getAvailableFaculties()[0].id;
            this.getGroupsOfFaculty();
          }

        },

        watch: {

            options: {
                handler() {
                  console.log('handler')
                    if (this.profileDrawer) {
                        this.closeDrawer();
                    }
                    let fullname = null;
                    if (this.searchString != null) {
                        fullname = this.searchString.trim();
                    }
                    const facultyId = this.getAvailableFacultyId();
                    this.$store.dispatch('defaultLoadUnionCard', {
                        fullname,
                        statuses: this.selectCardStatus,
                        facultyId: this.selectFacultyList,
                        groupId: this.selectGroupList,
                        page: this.options.page - 1,
                        limit: this.options.itemsPerPage,
                    });
                },
            },
        },

    };
</script>

<style scoped>

  .no-margin-padding {
    margin: 0;
    padding: 0;
  }
	.expander {
		height: 100%;
	}

	.text-name {
		display: block;
		height: min-content;
		margin-top: auto;
		margin-bottom: auto;
	}

	.text-other {
		display: block;
		height: min-content;
		margin: auto;
	}

</style>