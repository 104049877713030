import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/auth/login/Login.vue';
import Registration from './components/auth/registration/Registration.vue';
import RestorePassword from './components/auth/restore_password/RestorePassword.vue';
import Main from './components/system/Main.vue';

//  Пользователи
import Questionnaire from './components/system/user/questionary/Questionnaire.vue';
import MyProfile from './components/system/user/profile/MyProfile.vue';
import UserProfile from './components/system/user/profile/UserProfile.vue';

// Сотрудники
import Contingent from './components/system/staff/contingent/Contingent.vue';
import UnionCard from './components/system/staff/union_card/UnionCard.vue';
import Dashboard from './components/system/staff/dashboard/Dashboard.vue';

// Общее
import News from './components/system/share/news/News.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login,
        },
        // {
        //     path: '/registration',
        //     name: 'registration',
        //     component: Registration,
        // },
        // {
        //     path: '/restorePassword',
        //     name: 'resPass',
        //     component: RestorePassword,
        // },
        {
            path: '/main',
            name: 'main',
            component: Main,
            children: [
                {
                    path: '/news',
                    name: 'news',
                    component: News,
                },
                {
                    path: '/questionnaire',
                    name: 'questionnaire',
                    component: Questionnaire,
                },
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: Dashboard,
                },
                {
                    path: '/my-profile',
                    name: 'my-profile',
                    component: MyProfile,
                },
                {
                    path: '/user-profile/:userId',
                    name: 'user-profile',
                    props: true,
                    component: UserProfile,
                },
                {
                    path: '/contingent',
                    name: 'contingent',
                    component: Contingent,
                },
                {
                    path: '/unioncard',
                    name: 'unionCard',
                    component: UnionCard,
                },
            ],
        },
    ],
});
