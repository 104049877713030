<template>

  <div class="dashboard-container">
    <div v-if="loading" class="loader">Загрузка...</div>
    <iframe
        :src="iframeUrl"
        frameborder="0"
        width="100%"
        height="100%"
        allowtransparency
        @load="onIframeLoad"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      loading: true,
      iframeUrl: "https://analytic.profdstu.ru/",
    };
  },
  methods: {
    onIframeLoad() {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.dashboard-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #000;
}
</style>
