export const INCORRECT_DATA: string = 'Wrong credentials. Try another login/password';
export const INCORRECT_DATA_RU: string = 'Неправильные данные. Проверьте логин/пароль';
export const STATUS_ACTIVE = {text: 'Учится', value: 'active'};
export const STATUS_GRADUATE = {text: 'Окончил', value: 'graduate'};
export const STATUS_ACADEM = {text: 'Академический отпуск', value: 'academ'};
export const STATUS_WASTED = {text: 'Отчислен', value: 'wasted'};
export const STATUS_ARCHIVE = {text: 'Архив', value: 'archive'};
export const STATUS_DEADSOUL = {text: '«Мертвая душа»', value: 'deadsoul'};
export const PENDING: object = {text: 'Создан', value: 'PENDING', color: '#F44336'};
export const IN_WORK: object = {text: 'В работе', value: 'IN_WORK', color: '#F57C00'};
export const READY: object = {text: 'Готов', value: 'READY', color: '#4CAF50'};
export const ISSUED: object = {text: 'Выдан', value: 'ISSUED', color: '#01579B'};
export const CANCELED: object = {text: 'Отменён', value: 'CANCELED', color: '#9E9E9E'};
export const CLOSED: object = {text: 'Закрыт', value: 'CLOSED', color: '#9E9E9E'};
export const CARD_TYPE_CARD = {text: 'Карта', value: 'CARD'};
export const CARD_TYPE_PAPER = {text: 'Бумажный', value: 'PAPER'};
export const CARD_TYPE_VIRTUAL = {text: 'Виртуальный', value: 'VIRTUAL'};


