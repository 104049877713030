<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-navigation-drawer
        v-model="showDrawer"
        app
        right
        :disable-resize-watcher="false">

      <cont-profile-drawer v-if="profileDrawer"
                           :selectItem="profileItem"
                           @closeProfile="closeDrawer"></cont-profile-drawer>

      <cont-filter-drawer v-if="filterDrawer"
                          @setFilter="setFilter($event)"
                          @closeFilter="closeDrawer"></cont-filter-drawer>
    </v-navigation-drawer>
    <!--Таблица-->
    <v-card class="ma-xl-1 ma-sm-2 ma-xs-2">

      <!--show-select-->
      <v-card-title>Контингент
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          :item-class="itemClasses"
          :items-per-page="itemsPerPage"
          :mobile-breakpoint=300
          :server-items-length="totalItems"
          :loading=loadingItems
          loading-text="Загрузка..."
          :page.sync="page"
          fixed-header
          :hide-default-footer="true"
          @page-count="pageCount = $event"
          class="ma-2 ma-sm-0 ma-xl-0 ma-md-0 ma-lg-0"
      >

        <template v-slot:top>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  label="Поиск"
                  class="ma-1 pa-2"
                  single-line
                  dense
                  hide-details
                  clearable
                  @keydown.enter="searchStudent(searchString)"
              ></v-text-field>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="4" lg="6" xl="6">
              <v-spacer></v-spacer>
            </v-col>
            <v-col class="text-right" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <v-btn class="mr-3" outlined @click="openFilterDrawer">
                <v-icon>mdi-filter-variant</v-icon>
                Фильтр
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="8" lg="2" xl="2" md="2" sm="2">
              <p class="mr-5 ml-5 mt-2">
                <b>Всего записей:</b> {{ $store.getters.getContingentTotalItems }}
              </p>
            </v-col>
            <v-col cols="12" lg="8" xl="8" md="8" sm="8">
              <v-pagination
                  class="mt-2"
                  v-model="page"
                  :length="pageCount"
                  :total-visible="5"
                  @input="setPage"
              >
              </v-pagination>
            </v-col>
            <v-col cols="12" lg="2" xl="2" md="2" sm="2">
              <v-select
                  class="mr-5 ml-5 mt-2"
                  v-model="itemsPerPage"
                  :items="itemPerPageSelect"
                  label="Кол-во записей"
                  @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.isDebt="{item}">

          <div v-if="$vuetify.breakpoint.mdAndUp">
            <div v-if="!item.isMember">
              <v-btn
                  v-if="canUserMakeMember"
                  @click="openMemberDialog(item)"
                  text
                  color="primary"
                  ref="memberBtn"
              >Вступить
              </v-btn>
              <v-btn disabled text color="primary" v-else>
                Не вступил
              </v-btn>
              <!--	<v-btn
                          @click="isMemberDialog = !isMemberDialog"
                          text
                          color="primary"
                  >Вступить
                  </v-btn>-->
            </div>

            <v-btn
                v-if="countDebts(item)!==0 " text
                color="error"
                @click="openDebtsDialog(item)"
                ref="debtsBtn"> Оплатить
            </v-btn>

            <v-btn
                v-if="item.isMember&&countDebts(item)===0"
                text color="success">
              Оплачено
            </v-btn>
          </div>

          <div v-else>
            <div v-if="!item.isMember">
              <v-btn
                  v-if="canUserMakeMember"
                  @click="openMemberDialog(item)"
                  fab
                  outlined
                  x-small
                  color="primary"
                  ref="memberBtn"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn x-small outlined disabled fab color="primary" v-else>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-btn
                v-if="countDebts(item)!==0 "
                fab
                x-small
                outlined
                color="error"
                @click="openDebtsDialog(item)"
                ref="debtsBtn">
              <v-icon>mdi-exclamation</v-icon>
            </v-btn>

            <v-btn
                v-if="item.isMember&&countDebts(item)===0"
                fab
                x-small
                outlined
                color="success">
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:item.actionBtn={item}>
          <v-menu>
            <template v-slot:activator="{on: menu, attrs}">
              <v-btn
                  text
                  v-bind="attrs"
                  v-on="{...menu}">
                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="openUserProfile(item)">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Профиль</v-list-item-title>
              </v-list-item>
              <v-list-item
                  :disabled="!emptyStatement(item)"
                  @click="openStatementDialog(item)">
                <v-list-item-icon>
                  <v-icon>mdi-file-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Заявление
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="openStipendiaDialog(item)">
                <v-list-item-icon>
                  <v-icon>mdi-bitcoin</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Стипендия
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.profile.fullname="{item}">
          <div @click="openProfileDrawer(item)">
            {{ item.profile.fullname }}
          </div>
        </template>

        <template v-slot:item.group.faculty.shortname="{item}">
          <div @click="openProfileDrawer(item)">
            <div v-if="item.group !== null">{{ item.group.faculty.shortname }}</div>
          </div>
        </template>

        <template v-slot:item.group.course="{item}">
          <div @click="openProfileDrawer(item)">
            <div v-if="item.group !== null">{{ item.group.course }}</div>
          </div>
        </template>

        <template v-slot:item.hasStipendia="{item}">
          <div @click="openProfileDrawer(item)">
            <v-icon v-if="item.hasStipendia" color="success">mdi-plus-circle-outline</v-icon>
            <v-icon v-else color="error">mdi-minus-circle-outline</v-icon>
          </div>
        </template>

        <template v-slot:item.group.name="{item}">
          <div v-if="item.group!==null" style="cursor: pointer;"
               @click="searchStudenthByGroup(item)">
            {{ item.group.name }}
          </div>
        </template>


        <template v-slot:item.educationCondition="{item}">
          <div @click="openProfileDrawer(item)">
            {{ item.educationCondition }}
          </div>
        </template>

        <template v-slot:item.educationForm="{item}">
          <div @click="openProfileDrawer(item)">
            {{ item.educationForm }}
          </div>
        </template>

        <template v-slot:item.status="{item}">
          <div @click="openProfileDrawer(item)">
            {{ getStatusAsText(item.status) }}
          </div>
        </template>

        <template v-slot:no-data>
          Данные отсутствуют
        </template>

        <template v-slot:no-results>
          По запросу нет результатов
        </template>
      </v-data-table>
      <!--Диалог вступления в профсоюз-->
      <v-dialog
          v-model="isMemberDialog"
          max-width="500px"
          :fullscreen="$vuetify.breakpoint.mdAndDown">
        <v-card v-if="selectItem != null">
          <v-card-title>
            <v-btn icon @click="isMemberDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            Вступление
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="pl-1 pt-2">
              <v-row>
                <p class="subtitle-1 font-weight-bold pl-2 pr-2" v-if="selectItem.profile.sex === 'м'">
                  Студент: </p>
                <p class="subtitle-1 font-weight-bold pl-2 pr-2" v-else>Студентка: </p>
                <p class="subtitle-1">{{ selectItem.profile.fullname }}</p>
              </v-row>
              <v-text-field
                  v-model="dateFormatted"
                  label="Дата вступления"
                  v-mask="'##.##.####'"
                  prepend-icon="mdi-calendar"
              ></v-text-field>
              <v-dialog
                  v-model="calendarModal"
                  persistent
                  width="300px"
              >
                <v-date-picker v-model="memberDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="calendarModal = false">Закрыть</v-btn>
                  <v-btn text color="primary" @click="setSelectStartDate">OK</v-btn>
                </v-date-picker>
              </v-dialog>

              <v-row class="justify-center align-center">
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <v-btn small text color="primary" @click="setTodayDate">Сегодня</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <v-btn :disabled="selectItem.entranceSemester == null" small text color="primary"
                         @click="setStartEducationDate">Начало обучения
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <v-btn small text color="primary" @click="selectStartDate">Выбрать дату</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" text @click="isMemberDialog = false">Закрыть</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loadingIsMember" color="primary" @click="makeMember(selectItem)">Подтвердить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Диалог работы со взносами-->
      <v-dialog
          ref="debtsDialog"
          v-model="selectItemDialog"
          max-width="615px"
          :fullscreen="$vuetify.breakpoint.smAndDown">
        <dialog-debts :select-item="selectItem"
                      :contribution-size="contributionSize"
                      :key="indexDialog"
                      @closeDialog="selectItemDialog = false"></dialog-debts>
      </v-dialog>
      <!--Диалог "Заявление"-->
      <v-dialog
          v-model="showStatementDialog"
          hide-overlay
          :fullscreen="true"
      >
        <dialog-statement
            :statementSrc="statementSrc"
            @closeDialog="showStatementDialog=false"></dialog-statement>
      </v-dialog>

      <v-dialog
          max-width="600px"
          v-model="showStipendiaDialog"
          :key="indexDialog">
        <dialog-stipendia v-if="selectItem !== null"
                          :profileId="selectItem.id"
                          @closeDialog="showStipendiaDialog=false"></dialog-stipendia>
      </v-dialog>

    </v-card>
  </div>
</template>

<script>
import ProfileDrawer from '../../../share/ProfileDrawer.vue';
import FilterDrawer from './components/FilterDrawer.vue';
import DialogDebts from './components/DialogDebts.vue';
import moment from 'moment';
import {mask} from 'vue-the-mask';
import {STATUS_ACADEM, STATUS_ACTIVE, STATUS_GRADUATE, STATUS_WASTED, STATUS_ARCHIVE, STATUS_DEADSOUL} from '@/share/const-strings';
import DialogStatement from '@/components/system/staff/contingent/components/DialogStatement';
import DialogStipendia from '@/components/system/staff/contingent/components/DialogStipendia';

export default {
  name: 'Contingent',
  components: {DialogStipendia, DialogStatement, DialogDebts},
  directives: {
    mask,
  },
  data() {
    return {
      indexDialog: 0,
      headersForSpecialist: [
        {text: 'Взносы', sortable: false, align: 'center', value: 'isDebt'},
        {text: 'ФИО', sortable: false, align: 'left', value: 'profile.fullname'},
        {text: 'Курс', sortable: false, align: 'center', value: 'group.course'},
        {text: 'Стипендия', sortable: false, align: 'center', value: 'hasStipendia'},
        {text: 'Факультет', sortable: false, align: 'center', value: 'group.faculty.shortname'},
        {text: 'Группа', sortable: false, align: 'center', value: 'group.name'},
        {text: 'Условие обучения', sortable: false, align: 'center', value: 'educationCondition'},
        {text: 'Форма обучения', sortable: false, align: 'center', value: 'educationForm'},
        {text: 'Статус', sortable: false, align: 'center', value: 'status'},
        {text: 'Номер зачётки', sortable: false, align: 'center', value: 'studentNumber'},
        {text: 'Действия', sortable: false, align: 'center', value: 'actionBtn'},
      ],
      headers: [
        {text: 'Взносы', sortable: false, align: 'center', value: 'isDebt'},
        {text: 'ФИО', sortable: false, align: 'left', value: 'profile.fullname'},
        {text: 'Курс', sortable: false, align: 'center', value: 'group.course'},
        {text: 'Стипендия', sortable: false, align: 'center', value: 'hasStipendia'},
        {text: 'Группа', sortable: false, align: 'center', value: 'group.name'},
        {text: 'Условие обучения', sortable: false, align: 'center', value: 'educationCondition'},
        {text: 'Форма обучения', sortable: false, align: 'center', value: 'educationForm'},
        {text: 'Статус', sortable: false, align: 'center', value: 'status'},
        {text: 'Номер зачётки', sortable: false, align: 'center', value: 'studentNumber'},
        {text: 'Действия', sortable: false, align: 'center', value: 'actionBtn'},
      ],
      semesterItems: [],
      loadAddPaymentBtn: false,
      showLoadStatementDialog: false,
      showStatementDialog: false,
      showStipendiaDialog: false,
      statementSrc: null,
      detailedInfoDrawer: false,
      isMemberDialog: false,
      dateFormatted: '01.09.2020',
      memberDate: '2020-09-01',
      selectItemDialog: false,
      selectDebts: [],
      debtsWithPay: [],
      debtsWithOutPay: [],
      searchString: '',
      paid: 0,
      canUserMakeMember: false,
      maskMemberDate: '##.##.####',
      loadingIsMember: false,
      showDrawer: false,
      filterDrawer: false,
      profileDrawer: false,
      profileItem: {},
      page: 1,
      itemsPerPage: 30,
      itemPerPageSelect: [15, 30, 50],
      pageCount: 0,
      // ФИЛЬТРЫ
      calendarModal: false,
    };
  },
  methods: {
    setFilter() {
      this.$store.dispatch('getCurrentSemester');
      this.$store.dispatch('defaultLoadContingent', {
        search: this.searchString,
        facultyIds: this.$store.getters.getSelectFaculties,
        groupIds: this.$store.getters.getSelectGroups,
        courses: this.$store.getters.getSelectCourses,
        hasStipendia: this.$store.getters.getSelectStipends,
        isMember: this.$store.getters.getSelectMembers,
        educationForms: this.$store.getters.getSelectFormEducations,
        educationConditions: this.$store.getters.getSelectConditionEducations,
        status: this.$store.getters.getSelectStatuses,
        page: 0,
        limit: this.itemsPerPage,
      }).then(() => {
        this.page = 1;
      });
    },

    // Загрузить следующую/предыдущую страницу
    setPage() {
      this.$store.dispatch('defaultLoadContingent', {
        search: this.searchString,
        facultyIds: this.$store.getters.getSelectFaculties,
        groupIds: this.$store.getters.getSelectGroups,
        courses: this.$store.getters.getSelectCourses,
        hasStipendia: this.$store.getters.getSelectStipends,
        isMember: this.$store.getters.getSelectMembers,
        educationForms: this.$store.getters.getSelectFormEducations,
        educationConditions: this.$store.getters.getSelectConditionEducations,
        status: this.$store.getters.getSelectStatuses,
        page: this.page - 1,
        limit: this.itemsPerPage,
      }).then(() => {
      });
    },

    /*Поиск студента*/
    searchStudent(searchString) {
      this.$store.dispatch('getCurrentSemester');
      this.$store.dispatch('defaultLoadContingent', {
        search: searchString,
        groupIds: this.$store.getters.getSelectGroups,
        courses: this.$store.getters.getSelectCourses,
        hasStipendia: this.$store.getters.getSelectStipends,
        isMember: this.$store.getters.getSelectMembers,
        educationForms: this.$store.getters.getSelectFormEducations,
        educationConditions: this.$store.getters.getSelectConditionEducations,
        status: this.$store.getters.getSelectStatuses,
        page: 0,
        limit: this.itemsPerPage,
      }).then(() => {
        this.page = 1;
      });
    },

    /*countDebts - расчёт количество долгов */
    countDebts(item) {
      return item.debts.length;
    },

    /*hasDebts - есть ли долг*/
    hasDebts(item) {
      return item.debts.length > 0;
    },

    /*selectAllDebts - выбрать все долги для оплаты*/
    selectAllDebts(items) {
      const selectDebts = [];
      for (const item of items) {
        selectDebts.push(item.item);
      }
      return selectDebts;
    },
    openUserProfile(item) {
      this.selectItem = item;
      if (this.selectItem.id != null) {
        this.$router.push({name: 'user-profile', params: {userId: this.selectItem.id}});
      }
    },
    openStatementDialog(item) {
      if (item.profile === null) {
        this.statementSrc = null;
      }

      if (item.profile.files) {
        const files = item.profile.files;
        const filteredFiles = files.filter((file) => file.category === 'st');
        if (filteredFiles.length > 0) {
          filteredFiles.sort((a, b) => b.id - a.id);
          this.statementSrc = filteredFiles[0].file;
        } else {
          this.statementSrc = ''; // Если нет файлов категории 'st'
        }
      } else {
        this.statementSrc = '';
      }
      this.showStatementDialog = true;
    },
    emptyStatement(item) {
      if (item.profile === null) {
        return false;
      }

      if (item.profile.files) {
        for (const file of item.profile.files) {
          if (file.category === 'st') {
            return true;
          }
        }
        return false;
      }
    },
    openStipendiaDialog(item) {
      this.indexDialog++;
      this.selectItem = item;
      this.showStipendiaDialog = true;
    },

    /*Открыть окно погашения долгов*/
    openDebtsDialog(item) {
      this.selectItemDialog = true;
      this.indexDialog++;
      this.selectItem = item;
    },

    openMemberDialog(item) {
      this.selectItem = item;
      this.isMemberDialog = true;
    },

    makeMember(item) {
      this.loadingIsMember = true;
      let joinMemberDate = moment.parseZone(this.dateFormatted, 'DD.MM.YYYY');
      joinMemberDate = moment(joinMemberDate).format('YYYY-MM-DD');

      this.$store.dispatch('makeMember', {item, memberDate: joinMemberDate}).then(() => {
        this.$store.dispatch('updateContingentItemDebts', this.selectItem).then(() => {
        }).finally(() => {
          this.loadingIsMember = false;
          this.isMemberDialog = false;
        });
      });
    },

    searchStudenthByGroup(item) {
      this.searchString = '';
      this.$store.dispatch('getCurrentSemester');
      this.$store.commit('setSelectGroups', [item.group.id]);
      this.$store.dispatch('defaultLoadContingent', {
        facultyIds: this.$store.getters.getSelectFaculties,
        groupIds: item.group.id,
        status: this.selectStatus,
        page: 0,
        limit: this.itemsPerPage,
      }).then(() => {
            this.page = 1;
          },
      );
      this.openProfileDrawer(item);
    },

    setTodayDate() {
      this.dateFormatted = moment().locale('ru').format('L');
    },

    setStartEducationDate() {
      this.dateFormatted = moment(this.selectItem.entranceSemester.start).locale('ru').format('L');
    },

    setSelectStartDate() {
      this.dateFormatted = moment(this.memberDate).locale('ru').format('L');
      this.calendarModal = false;
    },

    selectStartDate() {
      this.calendarModal = true;
    },

    getStatusAsText(status) {
      switch (status) {
        case STATUS_ACTIVE.value:
          return STATUS_ACTIVE.text;
        case STATUS_GRADUATE.value:
          return STATUS_GRADUATE.text;
        case STATUS_ACADEM.value:
          return STATUS_ACADEM.text;
        case STATUS_WASTED.value:
          return STATUS_WASTED.text;
        case STATUS_ARCHIVE.value:
          return STATUS_ARCHIVE.text;
        case STATUS_DEADSOUL.value:
          return STATUS_DEADSOUL.text;
        default:
          return 'У-у-у-пс, непонятный статус';
      }
    },

    openProfileDrawer(item) {
      if (item.profile.student == null) {
        item.profile.student = {
          group: null,
        };
      }
      this.profileItem = {
        studentId: item.id,
        profileId: item.profile.id,
        photo: item.profile.mainPhoto,
        sex: item.profile.sex,
        files: item.profile.files,
        fullname: item.profile.fullname,
        group: item.group,
        studentNumber: item.studentNumber,
        phone: item.profile.phone,
        email: item.profile.email,
        birthday: item.profile.birthday,
        commentary: item.profile.commentary,
        debts: item.debts,
        entranceSemester: item.entranceSemester,
        memberFrom: item.memberFrom,
        memberEnd: item.memberEnd,
        elUnionCard: item.profile.elUnionCard,
      };

      this.filterDrawer = false;
      this.profileDrawer = true;
      this.showDrawer = true;
    },

    openFilterDrawer() {
      this.profileDrawer = false;
      this.filterDrawer = true;
      this.showDrawer = true;
    },

    closeDrawer() {
      this.showDrawer = false;
    },


    itemClasses: (item) => {
      if (item.blacklisted) {
        return {
          'red lighten-5': true,
        };
      } else if (item.profile.id === item.group?.faculty?.unionHead?.id) {
        return {
          'blue lighten-5': true,
        };
      } else if (item.profile.id === item.group?.unionOrg?.id) {
        return {
          'green lighten-5': true,
        };
      } else {
        return {};
      }
    },


  },
  computed: {
    contributionSize() {
      return this.$store.getters.getContingentContribution;
    },
    items() {
      return this.$store.getters.getContingentItems;
    },
    selectItem: {
      get() {
        return this.$store.getters.getContingentSelectItem;
      },
      set(newValue) {
        this.$store.commit('setContingentSelectItem', newValue);
      },
    },
    totalItems() {
      return this.$store.getters.getContingentTotalItems;
    },
    loadingItems() {
      return this.$store.getters.getContingentLoaderItems;
    },
  },

  created() {
    this.setTodayDate();
    this.canUserMakeMember = false;
    // Может ли пользователь вступать?
    if (this.$store.getters.getIsStaff || this.$store.getters.getIsSuperUser) {
      this.canUserMakeMember = true;
    } else {
      for (const group of this.$store.getters.getMyUserGroups) {
        if (group.id === '1' || group.id === '2') {
          this.canUserMakeMember = true;
          break;
        }
      }
    }

    if (this.$store.getters.getContingentItems.length === 0) {
      this.$store.dispatch('getCurrentSemester');
      this.$store.dispatch('defaultLoadContingent', {
        courses: this.$store.getters.getSelectCourses,
        hasStipendia: this.$store.getters.getSelectStipends,
        isMember: this.$store.getters.getSelectMembers,
        educationForms: this.$store.getters.getSelectFormEducations,
        educationConditions: this.$store.getters.getSelectConditionEducations,
        status: this.$store.getters.getSelectStatuses,
        page: this.page - 1,
        limit: this.itemsPerPage,
      }).then(() => {
        if (this.$store.getters.getMyUserInfo.getCountAvailableFaculties() !== 1) {
          this.headers = this.headersForSpecialist;
        }
      });
    }
  },
  component: {
    contProfileDrawer: ProfileDrawer,
    contFilterDrawer: FilterDrawer,
    contDialogDebts: DialogDebts,
    dialogStatement: DialogStatement,
  },
};
// TODO Показывать визуально, что включён какой-то фильтр
// TODO В профиле показывать стаж
// TODO Возможность оплатить следующий семестр
</script>

<style scoped lang="sass">


</style>