<template>
  <v-card>
    <v-card-title>
      <v-btn icon @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      Оплата взносов
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="mt-5">
        <p class="subtitle-1 font-weight-bold pl-2 pr-2" v-if="selectItem.profile.sex === 'м'">
          Студент: </p>
        <p class="subtitle-1 font-weight-bold pl-2 pr-2" v-else>Студентка: </p>
        <p class="subtitle-1">{{ selectItem.profile.fullname }}</p>
      </v-row>
      <div class="mt-n3" v-for="(item) in selectItem.debts">
        <div v-if="item.type === 'UNION_CARD'">
          <div>Профсоюзный билет</div>
          <v-radio-group
              class="mt-1 mb-1"
              mandatory
              :row="!$vuetify.breakpoint.mdAndDown">
            <v-radio
                @change="changePayRate(item, null)"
                label='Не оплатил'></v-radio>
            <v-radio
                @change="changePayRate(item, 0)"
                label='Оплачен ранее'></v-radio>
            <v-radio
                @change="changePayRate(item, unionCardContribution)"
                :label="unionCardContribution + ' ₽'"></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="mt-n3" v-for="(item) in selectItem.debts">
        <div v-if="item.type === 'SEMESTER'">
          <div v-if="selectItem.entranceSemester != null">
            {{ getSemesterNumber(item, selectItem.entranceSemester.year) }} семестр ({{ item.item.name }})
          </div>
          <div v-else>
            {{ item.item.name }}
          </div>
          <v-radio-group v-model="selectedRadios[item.item.id]" class="mt-1 mb-1" mandatory :row="!$vuetify.breakpoint.smAndDown">
            <v-radio
                @change="changePayRate(item, null)" color="primary"
                label='Не оплатил'></v-radio>
            <v-radio
                @change="changePayRate(item, 0)" color="primary"
                label='Оплачен ранее'></v-radio>
            <v-radio
                @change="changePayRate(item, contribution)" color="primary"
                :label="contribution + ' ₽'"></v-radio>
          </v-radio-group>
        </div>
      </div>
      <v-divider></v-divider>
      <v-tabs fixed-tabs v-model="tab">
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-tab>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-tab>
          <v-tab>
            <v-icon>mdi-bank</v-icon>
          </v-tab>
          <v-tab>
            <v-icon>$vuetify.icons.sbp</v-icon>
          </v-tab>
        </template>
        <template v-else>
          <v-tab>
            Наличная<br>оплата
          </v-tab>
          <v-tab>
            Безналичная<br>оплата
          </v-tab>
          <v-tab>
            Система быстрых<br>платежей
          </v-tab>
        </template>
        <v-tabs-items v-model="tab">
          <v-tab-item key="a">
            <div class="mt-4">
              Всего к оплате: <b>{{ getSummaryPayRate }} ₽</b>
            </div>
            <v-row class="mt-n3 mb-n4">
              <v-col cols="12" sm="12" md="6" lg="2" xl="2">
                <div class="mt-3">Получено:</div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="2" xl="2">
                <v-text-field
                    v-model="paid"
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <div>
              Сдача: <b>{{ getOddMoney }} ₽</b>
            </div>
          </v-tab-item>
          <v-tab-item key="b">
            <div>
              <div class="mt-4 mb-4 mr-5 ml-5">
                Всего к оплате: <b>{{ getSummaryPayRate }} ₽</b>
              </div>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <div class="mr-5 ml-5 text-center">
                    <img :src="getQrCode()" id="qrious" alt="" width="250" height="250"/>
                  </div>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <div class="mr-2 ml-2">
                    <p> БИК: <b>046015602</b>
                      <br>
                      Счёт: <b>40703810052090002227</b>
                      <br>
                      ИНН: <b>6165064021</b>
                      <br>
                      КПП: <b>616501001</b>
                      <br>
                      Получатель перевода:
                      <b>ППОО ДГТУ</b>
                      <br>
                      Назначение: <b>Профсоюзные взносы</b>
                      Л/с: <b>{{ selectItem.studentNumber }}</b>
                    </p>

                    <v-btn text small @click="clickBoard()">
                      <v-icon>mdi-clipboard-text-outline</v-icon>
                      Скопировать реквизиты
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item key="с">
            <div>
              <div class="mt-4 mb-4 mr-5 ml-5">
                Всего к оплате: <b>{{ getSummaryPayRate }} ₽</b>
              </div>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <div class="mr-5 ml-5 text-center">
                    <img :src="getQrCodeSBP()" id="qrious" alt="" width="250" height="250"/>
                  </div>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <div class="mr-2 ml-2">
                    <br>
                    <v-alert :type="alertType">
                      <v-progress-circular
                          v-if="alertType === 'error'"
                          :size="20"
                          indeterminate
                          color="white"
                      ></v-progress-circular>
                      {{ alertText }}
                    </v-alert>
                    <p>
                      <v-switch
                          @change="changePaySave()"
                          v-model="save_pay"
                          label="Привязать счет"></v-switch>
                    </p>
                    <p>
                      <a :href="getSpbURL()" target="_blank">Ссылка для оплаты</a>
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
          text
          color="primary"
          @click="closeDialog"
          @keydown.esc="closeDialog">
        Закрыть
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          :disabled="canClickBtnAddPayment"
          color="primary"
          @click="addPayments"
          @keydown.enter="addPayments"
          :loading=loadAddPaymentBtn
      >
        Подтвердить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Qrcode from 'qrcode';
import getEnv from '@/utils/getEnv';

// Реквизиты сбера
const SB_ST = 'ST00012';
const SB_NAME = 'Первичная профсоюзная организация обучающихся Донского государственного технического университета';
const SB_PERSONAL_ACC = '40703810052090002227';
const SB_BANK_NAME = 'ЮГО-ЗАПАДНЫЙ БАНК ПАО СБЕРБАНК';
const SB_BIC = '046015602';
const SB_CORRESP_ACC = '30101810600000000602';
const SB_PAYEE_INN = '6165064021';
const SB_KPP = '616501001';
const SB_CATEGORY = 'Профсоюзные взносы';
const SB_PURPOSE = 'Профсоюзные взносы';
const SPB_URL = getEnv('VUE_APP_SPB_URL');
const SPB_URL_FORM = getEnv('VUE_APP_SPB_URL_FORM');
// Взнос за университет(U) и колледж(C)
const U_CONTRIBUTION = 240;
const C_CONTRIBUTION = 88;
// Взнос за профсоюзный билет университет(U) и колледж(C)
const U_UC_CONTRIBUTION = 36;
const C_UC_CONTRIBUTION = 14;


const REQUISITES = 'Реквизиты \n БИК: ' + SB_BIC + '\n Счёт организации: ' + SB_PERSONAL_ACC + '\n ИНН: ' + SB_PAYEE_INN +
    '\n КПП: ' + SB_KPP + '\n Получатель перевода: ППОО ДГТУ \n Назначение: ' + SB_CATEGORY;

export default {
  name: 'DialogDebts',
  props: {
    selectItem: {},
    contributionSize: {},
  },
  data() {
    return {
      tab: null,
      selectDebts: [],
      paid: 0,
      loadAddPaymentBtn: false,
      contribution: U_CONTRIBUTION,
      unionCardContribution: U_UC_CONTRIBUTION,
      alertType: 'error',
      alertText: 'Не оплачен',
      debtsLength: 0,
      selectedRadios: {},
      save_pay: true
    };
  },
  methods: {
    checkDebts() {
      this.$store.dispatch('updateContingentItemDebts', this.selectItem).then((debtsLength) => {
        if (debtsLength === 0) {
          this.alertType = 'success';
          this.alertText = 'Оплата прошла';
          this.selectDebts = [];
        } else if (debtsLength < this.debtsLength) {
          this.alertType = 'error';
          this.alertText = 'Не оплачен';
          this.selectDebts = [];
          this.selectedRadios = {};
          this.debtsLength = debtsLength;
        } else {
          this.alertType = 'error';
          this.alertText = 'Не оплачен';
        }
      }).catch((error) => {
        console.warn('Ошибка при проверке долгов:', error);
      });
    },

    startCheckDebtsInterval() {
      this.intervalId = setInterval(this.checkDebts, 7000);
    },

    // Функция для остановки интервальной проверки checkDebts
    stopCheckDebtsInterval() {
      clearInterval(this.intervalId); // Останавливаем интервал
      this.intervalId = null; // Сбрасываем идентификатор интервала
    },

    getSpbURL() {
      return this.valueForQrSPB;
    },

    getQrCode() {
      let src = '';
      Qrcode.toDataURL(this.valueForQr, {
        errorCorrectionLevel: 'M',
      }, (err, url) => {
        src = url;
      });
      return src;
    },

    getQrCodeSBP() {
      let src = '';
      Qrcode.toDataURL(this.valueForQrSPB, {
        errorCorrectionLevel: 'M',
      }, (err, url) => {
        src = url;
      });
      return src;
    },

    // addPayments - добавить профвзнос
    addPayments() {
      this.loadAddPaymentBtn = true;
      this.$store.dispatch('addPayment', {
        id: this.selectItem.id,
        debts: this.selectDebts,
        by_cash: !this.tab,
      }).then(() => {
        this.$store.dispatch('updateContingentItemDebts', this.selectItem).then(() => {
        }).finally(() => {
          this.loadAddPaymentBtn = false;
          this.closeDialog();
        });
      });
    },

    getSemesterNumber(item, year) {
      let temp = (item.item.year - year) * 2;
      if (item.item.number === 2) {
        temp = temp.valueOf() + 1;
      }
      return temp.toString();
    },
    changePaySave(){
      this.getQrCodeSBP();

    },

    changePayRate(item, value) {
      const tempDebt = JSON.parse(JSON.stringify(item));
      tempDebt.item.payRate = value;
      for (const debt of this.selectDebts) {
        if (debt.item.id === tempDebt.item.id) {
          debt.item.payRate = value;
          this.getQrCode();
          return;
        }
      }
      this.selectDebts.push(tempDebt);
      this.getQrCode();
    },

    closeDialog() {
      this.stopCheckDebtsInterval();
      this.$emit('closeDialog');
    },

    clickBoard() {
      navigator.clipboard.writeText('Ф.И.О: ' + this.selectItem.profile.fullname + '\n'
          + REQUISITES
          + ' Л/с:' + this.selectItem.studentNumber
          + '\n Сумма к оплате: ' + this.getSummaryPayRate).then(() => {
        this.$store.dispatch('showAlert', {
          show: true,
          color: 'success',
          text: 'Реквизиты скопированы в буфер обмена',
        });
      }).catch(() => {
        this.$store.dispatch('showAlert', {
          show: true,
          color: 'alert',
          text: 'Ошибка! Этот браузер не поддерживает данную функцию. Используйте Ctrl+V',
        });
      });
    },
  },
  computed: {

    // Суммма к оплате за выбранные семестры
    getSummaryPayRate() {
      let summaryPayRate = 0;
      if (this.selectDebts != null) {
        for (const item of this.selectDebts) {
          summaryPayRate += item.item.payRate;
        }
      }
      return summaryPayRate;
    },

    // Сдача, которую необходимо выдать
    getOddMoney() {
      let summaryPayRate = 0;
      if (this.selectDebts != null) {
        for (const item of this.selectDebts) {
          summaryPayRate += item.item.payRate;
        }
      }

      if (this.paid <= summaryPayRate) {
        return 0;
      } else {
        return this.paid - summaryPayRate;
      }
    },

    canClickBtnAddPayment() {
      for (const debt of this.selectDebts) {
        if (debt.item.payRate != null) {
          return false;
        }
      }
      return true;
    },

    valueForQr() {
      return SB_ST + '|' +
          'Name=' + SB_NAME + '|' +
          'PersonalAcc=' + SB_PERSONAL_ACC + '|' +
          'BankName=' + SB_BANK_NAME + '|' +
          'BIC=' + SB_BIC + '|' +
          'CorrespAcc=' + SB_CORRESP_ACC + '|' +
          'PayeeINN=' + SB_PAYEE_INN + '|' +
          'KPP=' + SB_KPP + '|' +
          'Purpose=' + SB_PURPOSE + ' Л/с:' + this.selectItem.studentNumber + '|' +
          'Category=' + SB_CATEGORY + '|' +
          'PersACC=' + this.selectItem.studentNumber + '|' +
          'LastName=' + this.selectItem.profile.familyname + '|' +
          'FirstName=' + this.selectItem.profile.firstname + '|' +
          'MiddleName=' + this.selectItem.profile.surname + '|' +
          'Sum=' + this.getSummaryPayRate * 100;
    },
    valueForQrSPB() {
      return SPB_URL + SPB_URL_FORM + '?full_name=' +  encodeURIComponent(this.selectItem.profile.familyname + ' ' +
          this.selectItem.profile.firstname + ' ' + this.selectItem.profile.surname) + '&student_number=' +
          this.selectItem.studentNumber + '&order_sum=' + this.getSummaryPayRate + (this.save_pay ? '' : '&save_payment_method=False')
    },
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        this.getQrCode();
      }
      if (this.tab === 2) { // Проверяем, является ли новая вкладка вкладкой СБП
        this.startCheckDebtsInterval(); // Запускаем интервал при открытии вкладки СБП
      } else {
        this.stopCheckDebtsInterval(); // Останавливаем интервал при закрытии вкладки СБП
      }

    },
  },
  created() {
    this.debtsLength = this.selectItem.debts.length;
    const educationLevel = this.selectItem.group?.educationLevel || '';
    if (educationLevel.includes('СПО')) {
      this.contribution = this.contributionSize.payRateSpo;
      this.unionCardContribution = C_UC_CONTRIBUTION;
    } else {
      this.contribution = this.contributionSize.payRate;
      this.unionCardContribution = U_UC_CONTRIBUTION;
    }
    for (const debt of this.selectItem.debts) {
      if (debt.type === 'SEMESTER') {
        debt.item.payRate = this.contribution;
      }
    }
    this.getQrCode();
  },
};
</script>

<style scoped>

</style>