import {
    CARD_TYPE_CARD,
    CARD_TYPE_PAPER, CARD_TYPE_VIRTUAL,
    STATUS_ACADEM,
    STATUS_ACTIVE,
    STATUS_GRADUATE,
    STATUS_WASTED,
    STATUS_ARCHIVE,
    STATUS_DEADSOUL,
} from '@/share/const-strings';


export function getSemesterNumber(item: any, year: number) {
        let numberSemester = (item.item.year - year) * 2;
        if (item.item.number === 2) {
            numberSemester = numberSemester.valueOf() + 1;
        }
        return numberSemester.toString();
    }

export function getStudentStatusAsText(status: string): string {
    switch (status) {
        case STATUS_ACTIVE.value:
            return STATUS_ACTIVE.text;
        case STATUS_GRADUATE.value:
            return STATUS_GRADUATE.text;
        case STATUS_ACADEM.value:
            return STATUS_ACADEM.text;
        case STATUS_WASTED.value:
            return STATUS_WASTED.text;
        case STATUS_ARCHIVE.value:
            return STATUS_ARCHIVE.text;
        case STATUS_DEADSOUL.value:
            return STATUS_DEADSOUL.text;
        default:
            return 'У-у-у-пс, непонятный статус';
    }
}
export function getTypeCard(cardType: string): string {
    switch (cardType) {
        case CARD_TYPE_CARD.value:
            return CARD_TYPE_CARD.text;
        case CARD_TYPE_PAPER.value:
            return CARD_TYPE_PAPER.text;
        case CARD_TYPE_VIRTUAL.value:
            return CARD_TYPE_VIRTUAL.text;
        default:
            return 'Что-то непонятное';
    }
}
